button.o2o-facebook-button {
    border-radius: 0;
    width: 100%;
    height: 45px;
    background: transparent;
    border: none;
    position: absolute;
    z-index: 22;
    color: transparent;
    cursor: pointer;
}
.landing .landing-form{
    margin: 0 !important;
}
.login-form-acc{
    border: 1px solid #d9d9d9;
    padding: 30px;
    .ant-form-item{
        margin-bottom: 10px;
    }
}

.inputStyle {
    width: 3rem !important;
    height: 3rem;
    margin: 0 10px;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }

  @media only screen and (max-width: 470px) {
    .inputStyle {
        width: 2rem !important;
        height: 2rem;
        margin: 0 5px;
        font-size: 1rem;
      }
  }