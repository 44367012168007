@import '~antd/dist/antd.css';
@import '~slick-carousel/slick/slick.css';
@import "~slick-carousel/slick/slick-theme.css";
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400&subset=vietnamese');

body {
    .console--o2oFormItem__hideMessage {
        .ant-form-explain {
            display: none;
        }
    }

    .ant-drawer-title {
        text-transform: uppercase !important;
    }

    input,
    button {
        font-size: 14px;
        font-weight: 400;
    }

    h1.ant-typography {
        font-size: 30px;
        font-weight: 300
    }

    .ant-form-explain {
        margin-bottom: 10px
    }

    .btn-social {
        &.btn-facebook {
            background: #00A6FF;
            border-color: #00A6FF;
        }

        &.btn-google {
            background: #F95F62;
            border-color: #F95F62;
        }

        i {
            float: left;
            padding-right: 15px;
            padding-top: 10px;
            height: 100%;
            border-right: 1px solid;

            svg {
                font-size: 20px
            }
        }

        span {
            margin-left: 8px;
            padding-top: 12px;
            font-size: 13px;
            float: left;
        }
    }

    .o2o-main-sider .logo-img {
        width: 50px;
    }

    .ant-typography {
        font-weight: 300 !important
    }

    .o2o-text-gray {
        color: #AEB5BE
    }

    .o2o-text-ellipsis {
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .ant-typography.o2o-title-gray {
        color: #343F4B
    }

    .ant-table-tbody > tr > td {
        word-wrap: break-word;
        word-break: break-all;
    }

    .ant-table-fixed-left,
    .ant-table-fixed-right {

        table td,
        table th {
            background: #fafafa
        }
    }

    .ant-layout-header {
        background: var(--primary-color);
    }

    .drowdown-menu-right .o2o-popover .ant-avatar-circle {
        background: var(--primary-color) !important;
        border: 1px solid #fff !important;
    }

    .o2o-popover-inner .ant-popover-inner-content {
        border-color: var(--primary-color) !important;

        .ant-avatar-icon {
            background: var(--primary-color) !important;
            border-color: var(--primary-color) !important;
        }
    }

    .ant-menu-submenu-selected .ant-menu-submenu-title .anticon,
    .ant-menu-submenu-selected .ant-menu-submenu-title .anticon + span {
        color: var(--primary-color);
    }

    .ant-layout-sider-trigger {
        background: rgb(104, 101, 108);
        opacity: 0.5;
    }

    .containerInner {
        background: #fff;
        padding: 24px;
        box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, .01);
        min-height: calc(100vh - 230px);
        position: relative;
        margin-bottom: 24px;
    }

    .contentLayout {
        min-height: calc(100% - 72px);
    }

    .ant-layout-header {
        z-index: 100 !important;
    }

    .stiky-wrapper-table {
        position: relative;

        .table-sticky {
            .ant-table-placeholder {
                display: none
            }
        }
    }

    .dashboard-page {
        overflow: hidden;
    }

    .ant-table-pagination.ant-pagination {
        float: left;
    }

    .landing {
        .landing-form {
            // max-width: 400px !important;
            // min-width: 350px !important;
            border-radius: 3px;
            border: 1px solid #d9d9d9;
            padding: 50px 30px 30px 30px;
        }

        @media only screen and (max-width: 480px) and (min-width: 320px) {
            .landing-form {
                // max-width: none !important;
                // min-width: auto !important;
                border-radius: 3px;
                border: 1px solid #d9d9d9;
                padding: 20px 10px 10px 10px;
            }
        }

    }

    .industry-input {
        .ant-select-selection__placeholder {
            padding-left: 20px;
        }

        .ant-select-selection-selected-value {
            padding-left: 20px;
        }
    }

    .ant-table-thead > tr > th {
        background: #F1F2F5 !important;
        font-weight: "normal" !important
    }

    .ant-menu-submenu-selected .ant-menu-submenu-title,
    .ant-menu-submenu-selected .ant-menu-submenu-title .anticon,
    .ant-menu-submenu-selected .ant-menu-submenu-title .anticon + span {
        color: var(--primary-color);
    }

    /* dark styling of the editor */
    div.jsoneditor,
    div.jsoneditor-menu {
        border-color: #4b4b4b;
    }

    div.jsoneditor-menu {
        background-color: #4b4b4b;
    }

    div.jsoneditor-tree,
    div.jsoneditor textarea.jsoneditor-text {
        background-color: #666666;
        color: #ffffff;
    }

    div.jsoneditor-field,
    div.jsoneditor-value {
        color: #ffffff;
    }

    table.jsoneditor-search div.jsoneditor-frame {
        background: #808080;
    }

    tr.jsoneditor-highlight,
    tr.jsoneditor-selected {
        background-color: #808080;
    }

    div.jsoneditor-field[contenteditable=true]:focus,
    div.jsoneditor-field[contenteditable=true]:hover,
    div.jsoneditor-value[contenteditable=true]:focus,
    div.jsoneditor-value[contenteditable=true]:hover,
    div.jsoneditor-field.jsoneditor-highlight,
    div.jsoneditor-value.jsoneditor-highlight {
        background-color: #808080;
        border-color: #808080;
    }

    div.jsoneditor-field.highlight-active,
    div.jsoneditor-field.highlight-active:focus,
    div.jsoneditor-field.highlight-active:hover,
    div.jsoneditor-value.highlight-active,
    div.jsoneditor-value.highlight-active:focus,
    div.jsoneditor-value.highlight-active:hover {
        background-color: #b1b1b1;
        border-color: #b1b1b1;
    }

    div.jsoneditor-tree button:focus {
        background-color: #868686;
    }

    /* coloring of JSON in tree mode */
    div.jsoneditor-readonly {
        color: #acacac;
    }

    div.jsoneditor td.jsoneditor-separator {
        color: #acacac;
    }

    div.jsoneditor-value.jsoneditor-string {
        color: #00ff88;
    }

    div.jsoneditor-value.jsoneditor-object,
    div.jsoneditor-value.jsoneditor-array {
        color: #bababa;
    }

    div.jsoneditor-value.jsoneditor-number {
        color: #ff4040;
    }

    div.jsoneditor-value.jsoneditor-boolean {
        color: #ff8048;
    }

    div.jsoneditor-value.jsoneditor-null {
        color: #49a7fc;
    }

    div.jsoneditor-value.jsoneditor-invalid {
        color: white;
    }
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}