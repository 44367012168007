@import url(https://fonts.googleapis.com/css?family=Roboto:300,400&subset=vietnamese);
body {
  /* dark styling of the editor */
  /* coloring of JSON in tree mode */ }
  body .console--o2oFormItem__hideMessage .ant-form-explain {
    display: none; }
  body .ant-drawer-title {
    text-transform: uppercase !important; }
  body input,
  body button {
    font-size: 14px;
    font-weight: 400; }
  body h1.ant-typography {
    font-size: 30px;
    font-weight: 300; }
  body .ant-form-explain {
    margin-bottom: 10px; }
  body .btn-social.btn-facebook {
    background: #00A6FF;
    border-color: #00A6FF; }
  body .btn-social.btn-google {
    background: #F95F62;
    border-color: #F95F62; }
  body .btn-social i {
    float: left;
    padding-right: 15px;
    padding-top: 10px;
    height: 100%;
    border-right: 1px solid; }
    body .btn-social i svg {
      font-size: 20px; }
  body .btn-social span {
    margin-left: 8px;
    padding-top: 12px;
    font-size: 13px;
    float: left; }
  body .o2o-main-sider .logo-img {
    width: 50px; }
  body .ant-typography {
    font-weight: 300 !important; }
  body .o2o-text-gray {
    color: #AEB5BE; }
  body .o2o-text-ellipsis {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  body .ant-typography.o2o-title-gray {
    color: #343F4B; }
  body .ant-table-tbody > tr > td {
    word-wrap: break-word;
    word-break: break-all; }
  body .ant-table-fixed-left table td,
  body .ant-table-fixed-left table th,
  body .ant-table-fixed-right table td,
  body .ant-table-fixed-right table th {
    background: #fafafa; }
  body .ant-layout-header {
    background: var(--primary-color); }
  body .drowdown-menu-right .o2o-popover .ant-avatar-circle {
    background: var(--primary-color) !important;
    border: 1px solid #fff !important; }
  body .o2o-popover-inner .ant-popover-inner-content {
    border-color: var(--primary-color) !important; }
    body .o2o-popover-inner .ant-popover-inner-content .ant-avatar-icon {
      background: var(--primary-color) !important;
      border-color: var(--primary-color) !important; }
  body .ant-menu-submenu-selected .ant-menu-submenu-title .anticon,
  body .ant-menu-submenu-selected .ant-menu-submenu-title .anticon + span {
    color: var(--primary-color); }
  body .ant-layout-sider-trigger {
    background: #68656c;
    opacity: 0.5; }
  body .containerInner {
    background: #fff;
    padding: 24px;
    box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.01);
    min-height: calc(100vh - 230px);
    position: relative;
    margin-bottom: 24px; }
  body .contentLayout {
    min-height: calc(100% - 72px); }
  body .ant-layout-header {
    z-index: 100 !important; }
  body .stiky-wrapper-table {
    position: relative; }
    body .stiky-wrapper-table .table-sticky .ant-table-placeholder {
      display: none; }
  body .dashboard-page {
    overflow: hidden; }
  body .ant-table-pagination.ant-pagination {
    float: left; }
  body .landing .landing-form {
    border-radius: 3px;
    border: 1px solid #d9d9d9;
    padding: 50px 30px 30px 30px; }
  @media only screen and (max-width: 480px) and (min-width: 320px) {
    body .landing .landing-form {
      border-radius: 3px;
      border: 1px solid #d9d9d9;
      padding: 20px 10px 10px 10px; } }
  body .industry-input .ant-select-selection__placeholder {
    padding-left: 20px; }
  body .industry-input .ant-select-selection-selected-value {
    padding-left: 20px; }
  body .ant-table-thead > tr > th {
    background: #F1F2F5 !important;
    font-weight: "normal" !important; }
  body .ant-menu-submenu-selected .ant-menu-submenu-title,
  body .ant-menu-submenu-selected .ant-menu-submenu-title .anticon,
  body .ant-menu-submenu-selected .ant-menu-submenu-title .anticon + span {
    color: var(--primary-color); }
  body div.jsoneditor,
  body div.jsoneditor-menu {
    border-color: #4b4b4b; }
  body div.jsoneditor-menu {
    background-color: #4b4b4b; }
  body div.jsoneditor-tree,
  body div.jsoneditor textarea.jsoneditor-text {
    background-color: #666666;
    color: #ffffff; }
  body div.jsoneditor-field,
  body div.jsoneditor-value {
    color: #ffffff; }
  body table.jsoneditor-search div.jsoneditor-frame {
    background: #808080; }
  body tr.jsoneditor-highlight,
  body tr.jsoneditor-selected {
    background-color: #808080; }
  body div.jsoneditor-field[contenteditable=true]:focus,
  body div.jsoneditor-field[contenteditable=true]:hover,
  body div.jsoneditor-value[contenteditable=true]:focus,
  body div.jsoneditor-value[contenteditable=true]:hover,
  body div.jsoneditor-field.jsoneditor-highlight,
  body div.jsoneditor-value.jsoneditor-highlight {
    background-color: #808080;
    border-color: #808080; }
  body div.jsoneditor-field.highlight-active,
  body div.jsoneditor-field.highlight-active:focus,
  body div.jsoneditor-field.highlight-active:hover,
  body div.jsoneditor-value.highlight-active,
  body div.jsoneditor-value.highlight-active:focus,
  body div.jsoneditor-value.highlight-active:hover {
    background-color: #b1b1b1;
    border-color: #b1b1b1; }
  body div.jsoneditor-tree button:focus {
    background-color: #868686; }
  body div.jsoneditor-readonly {
    color: #acacac; }
  body div.jsoneditor td.jsoneditor-separator {
    color: #acacac; }
  body div.jsoneditor-value.jsoneditor-string {
    color: #00ff88; }
  body div.jsoneditor-value.jsoneditor-object,
  body div.jsoneditor-value.jsoneditor-array {
    color: #bababa; }
  body div.jsoneditor-value.jsoneditor-number {
    color: #ff4040; }
  body div.jsoneditor-value.jsoneditor-boolean {
    color: #ff8048; }
  body div.jsoneditor-value.jsoneditor-null {
    color: #49a7fc; }
  body div.jsoneditor-value.jsoneditor-invalid {
    color: white; }

@-webkit-keyframes blinker {
  50% {
    opacity: 0; } }

@keyframes blinker {
  50% {
    opacity: 0; } }

button.o2o-facebook-button {
  border-radius: 0;
  width: 100%;
  height: 45px;
  background: transparent;
  border: none;
  position: absolute;
  z-index: 22;
  color: transparent;
  cursor: pointer; }

.landing .landing-form {
  margin: 0 !important; }

.login-form-acc {
  border: 1px solid #d9d9d9;
  padding: 30px; }
  .login-form-acc .ant-form-item {
    margin-bottom: 10px; }

.inputStyle {
  width: 3rem !important;
  height: 3rem;
  margin: 0 10px;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3); }

@media only screen and (max-width: 470px) {
  .inputStyle {
    width: 2rem !important;
    height: 2rem;
    margin: 0 5px;
    font-size: 1rem; } }

.drowdown-menu-right button.ant-btn {
  background: transparent;
  border: none;
  color: #fff;
  box-shadow: none; }
  .drowdown-menu-right button.ant-btn:hover {
    background: transparent;
    border: none;
    opacity: 0.5; }

.landing .landing-form {
  margin: 0 auto; }

.landing .logo {
  width: 200px !important;
  margin: 0 28px 16px 0 !important; }
  .landing .logo a {
    color: #fff;
    font-size: 17px; }

.dashboard-layout-wrapper .logo a {
  font-size: 20px; }

.dashboard-layout-wrapper .logo i {
  top: 19px; }

.dashboard-layout-wrapper .cascader-org input {
  border: none; }

.dashboard-layout-wrapper .cascader-org .ant-cascader-picker:hover i.ant-cascader-picker-clear {
  color: #fff;
  background: none; }

.dashboard-layout-wrapper .cascader-org i {
  color: #fff; }

.dashboard-layout-wrapper .text-header {
  color: #fff; }

.dashboard-layout-wrapper.ant-layout {
  background: #f0f2f5; }

.o2o-main-sider .logo-img {
  width: 80px; }

.ant-drawer-content-wrapper .logo-img {
  margin-bottom: 0;
  width: 80px; }

.list-company {
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  width: 100%; }
  .list-company .ant-list-item {
    padding: 20px 24px; }
    .list-company .ant-list-item:hover {
      cursor: pointer; }
      .list-company .ant-list-item:hover i {
        color: #956DD1 !important; }

.p-company {
  font-size: 12px;
  color: #AEB5BE; }

.input-search-company-name .ant-input {
  border-radius: 10px !important; }


