.list-company {
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    .ant-list-item {
        padding: 20px 24px;
        &:hover {
            cursor: pointer;
            i {
                color: #956DD1 !important
            }
        }
    }
    width: 100%;
}

.p-company {
    font-size: 12px;
    color: #AEB5BE;
}

.input-search-company-name {
    .ant-input{
        border-radius: 10px !important;
    }
    
}