.drowdown-menu-right {
    button.ant-btn {
        background: transparent;
        border: none;
        color: #fff;
        box-shadow: none;
        &:hover {
            background: transparent;
            border: none;
            opacity: 0.5;
        }
    }
}

.landing {
    .landing-form {
        margin: 0 auto;
    }
    .logo {
        width: 200px !important;
        margin: 0 28px 16px 0 !important;
        a {
            color: #fff;
            font-size: 17px;
        }
    }
}