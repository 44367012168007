.dashboard-layout-wrapper {
    .logo {
        a {
            font-size: 20px;
        }
        i {
            top: 19px
        }
    }
    .cascader-org {
        input {
            border: none
        }
        .ant-cascader-picker:hover {
            i.ant-cascader-picker-clear {
                color: #fff;
                background: none
            }
        }
        i {
            color: #fff
        }
    }
    .text-header {
        color: #fff
    }
    &.ant-layout {
        background: #f0f2f5
    }
}

.o2o-main-sider {
    .logo-img {
        width: 80px
    }
}

.ant-drawer-content-wrapper {
    .logo-img {
        margin-bottom: 0;
        width: 80px
    }
}